import { useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { getPartners } from "src/api/partner";
import { checkAccountHolderAsync } from "src/api/popbill/popbill-api";
import {
  PopbillAccountModel,
  PopbillAccountRequest,
  PopbillBizAddRequest,
  PopbillBizDetailModel,
} from "src/api/popbill/popbill-types";
import { getBankCodeListAsync } from "src/api/provider/provider-api";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import { onlyNumber } from "src/utils";
import { popbillAccountError } from "../popbill-constant";

type Props = {
  control: Control<PopbillBizAddRequest>;
  popbill?: PopbillBizDetailModel;
  watch: UseFormWatch<PopbillBizAddRequest>;
  setValue: UseFormSetValue<PopbillBizAddRequest>;
  errors: FieldErrors<PopbillBizAddRequest>;
  register: UseFormRegister<PopbillBizAddRequest>;
};
const PopbillAccountForm = ({
  control, //
  popbill,
  watch,
  setValue,
  errors,
  register,
}: Props) => {
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });
  const popbillAcctList = useMemo(() => popbill?.popbillAcctList, [popbill]);

  // 팝빌 은행코드 api
  const { executeAsync: getBankCodeList } = useApiOperation(getBankCodeListAsync);

  // 팝빌 예금주확인 api
  const { executeAsync: checkAccountHolder } = useApiOperation(checkAccountHolderAsync, {
    doNotErrorHandleModal: true,
  });

  // 파트너 목록 조회
  const { executeAsync: getPartnersAsync } = useApiOperation(getPartners);

  // 유효성 검사
  useEffect(() => {
    const currentAccounts = watch("popbillAcctList") || [];

    currentAccounts?.forEach((account, idx: number) => {
      register(`popbillAcctList.${idx}.bankCode`, {
        required: { value: true, message: "은행을 선택해주세요." },
      });

      register(`popbillAcctList.${idx}.accountNumber`, {
        required: { value: true, message: "계좌번호를 입력해주세요." },
        minLength: { value: 8, message: "계좌번호는 8자 ~ 14자 숫자를 입력해주세요" },
        maxLength: { value: 14, message: "계좌번호는 8자 ~ 14자 숫자를 입력해주세요" },
      });
      register(`popbillAcctList.${idx}.accountHolder`, {
        validate: {
          required: (value) => {
            const bankCode = watch(`popbillAcctList.${idx}.bankCode`);
            const accountNumber = watch(`popbillAcctList.${idx}.accountNumber`);
            let result = true;
            let message = "";
            if (bankCode && accountNumber && !value) {
              result = false;
              message = "예금주 정보 확인은 필수사항입니다.";
              setAlertModal({ isOpen: true, message });
            }

            return result || message;
          },
        },
      });
    });
  }, [
    register, //
    watch("popbillAcctList"),
  ]);

  // 파트너 목록 보여주기 위한 api
  const { data: partnerList } = useQuery({
    queryKey: [
      "partnerList",
      popbillAcctList?.flatMap((item) => item.popbillAcctAllocList?.map((item) => item.partnerId)),
    ],
    queryFn: () => {
      const request = {
        page: 0,
        size: 20,
        id: popbillAcctList
          ?.flatMap(
            (item: PopbillAccountModel) =>
              item.popbillAcctAllocList?.map((item) => item.partnerId) || [],
          )
          .join(","),
      };

      return getPartnersAsync(request);
    },
    select: (response) => response.data.data.content || [],
    enabled: (() => {
      const hasAllocList = popbillAcctList?.some((item) => {
        return Array.isArray(item?.popbillAcctAllocList) && item.popbillAcctAllocList.length > 0;
      });

      return Boolean(hasAllocList);
    })(),
  });

  // 팝빌 은행코드 목록 조회 api
  const { data: bankCodeList } = useQuery({
    queryKey: ["bankCodeList"],
    queryFn: () => getBankCodeList({ codeGroup: "POPBILL_BANK_CODE" }),
    select: (response) => {
      const convertList = response.data.data.content.map((item) => ({
        value: item.code,
        label: item.desc,
      }));
      return convertList.sort((a, b) => (a.label < b.label ? -1 : 1));
    },
  });

  // 예금주 조회 확인 api
  const confirmAccountHolder = async (request: {
    accountNumber: string;
    bankCode: string;
    idx: number;
  }) => {
    if (!request.accountNumber || !request.bankCode) {
      setAlertModal({
        isOpen: true,
        message: "은행과 계좌번호를 모두 입력한 후 조회 버튼을 눌러주세요.",
      });
      return;
    }

    const { data, status } = await checkAccountHolder({
      bankCode: request.bankCode,
      accountNumber: request.accountNumber,
    });

    if (status >= 200 && status < 300) {
      if (data.data.result === "100") {
        setValue(`popbillAcctList.${request.idx}.accountHolder`, data.data.accountName);
      } else {
        setAlertModal({
          isOpen: true,

          message:
            popbillAccountError[data.data.result as keyof typeof popbillAccountError] ||
            "알 수 없는 오류가 발생하였습니다.",
        });
      }
    }
  };

  // 계좌 삭제 row setValue
  const onDeleteAccount = (accountIdx: number) => {
    const updatedAccounts = watch("popbillAcctList")
      ?.map((account, idx) => {
        if (idx === accountIdx) {
          return account.pbAccountId
            ? { ...account, isDeleted: true } // 기존 계좌는 isDeleted 처리
            : null; // 신규 계좌는 null 처리
        }
        return account;
      })
      .filter(Boolean); // null 값 제거

    setValue("popbillAcctList", updatedAccounts as PopbillAccountRequest[]);
  };

  return (
    <div className="contents-container__wrap popbill-account">
      <div className="contents-container__wrap-contents">
        <article className="pt20 pb0 px0 contents-container__wrap-article full-width">
          <div className="contents-container__sub-title">
            <h2 className="mr5">계좌 조회 대상</h2>
            <BaseTooltip
              touchIcon="QUESTION"
              className="minmax16"
              children={
                <ul className="pl15">
                  <li className="mb10">
                    계좌 조회 대상의 각 계좌의 등록 상태는 Ctrl.room_정산정보에서 확인할 수
                    있습니다.
                  </li>
                  <li>
                    계좌 조회 대상 파트너에서 동일한 계좌가 등록된 정산정보 상세에서 조회
                    가능합니다.
                  </li>
                </ul>
              }
            />
          </div>
          <section className="contents-container__1200">
            <table className="inner-table" width="1100">
              <thead>
                <tr className="border-bottom border-top">
                  <th className="minmax160">
                    <span className="required">은행</span>
                  </th>
                  <th className="minmax200">
                    <span className="required">계좌번호</span>
                  </th>
                  <th className="minmax280">
                    <span className="required">예금주</span>
                  </th>
                  <th className="minmax420">
                    <div className="flex-center-center">
                      <span>파트너 권한 부여</span>
                      <BaseTooltip
                        touchIcon="QUESTION"
                        className="ml5 minmax16"
                        children={
                          <ul className="pl15">
                            <li className="mb10">
                              파트너 권한 부여는 수정 화면에서 설정하거나 추가할 수 있습니다.
                            </li>
                            <li>
                              설정 시, 해당 파트너는 연동된 API의 서비스를 이용할 수 있습니다.
                            </li>
                          </ul>
                        }
                      />
                    </div>
                  </th>
                  <th className="minmax40">
                    <button
                      className="base-add-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setValue(`popbillAcctList`, [
                          ...(watch("popbillAcctList") || []),
                          {
                            bankCode: "",
                            accountHolder: "",
                            accountNumber: "",
                          },
                        ]);
                      }}
                    ></button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {watch("popbillAcctList")?.map(
                  (account: PopbillAccountModel, accountIdx: number) => {
                    return (
                      <Fragment key={accountIdx}>
                        <tr className={account.isDeleted ? "d-none" : ""}>
                          <td width="160">
                            <Controller
                              control={control}
                              name={`popbillAcctList.${accountIdx}.bankCode`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <BaseSelect
                                    stateOptions={bankCodeList || []}
                                    value={value}
                                    setStateValue={(bankCode: string) => {
                                      // 프로바이더 상세에 저장된 bankCode
                                      const savedBankCode =
                                        popbill?.popbillAcctList?.[accountIdx]?.bankCode;

                                      // 기존 데이터 변경되면  예금주 정보 재확인 필요
                                      if (savedBankCode !== bankCode) {
                                        setValue(`popbillAcctList.${accountIdx}.accountHolder`, "");
                                      }
                                      onChange(bankCode);
                                    }}
                                  />
                                );
                              }}
                            />
                          </td>

                          <td width="200">
                            <Controller
                              control={control}
                              name={`popbillAcctList.${accountIdx}.accountNumber`}
                              render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                              }) => {
                                return (
                                  <BaseInput
                                    onChange={(accountNumber: string) => {
                                      const saveAccountNumber =
                                        popbill?.popbillAcctList?.[accountIdx]?.accountNumber;

                                      // 기존 데이터 변경되면  예금주 정보 재확인 필요
                                      if (saveAccountNumber !== accountNumber) {
                                        setValue(`popbillAcctList.${accountIdx}.accountHolder`, "");
                                      }
                                      onChange(accountNumber);
                                    }}
                                    value={value ? onlyNumber(value) : ""}
                                    name={name}
                                    className="px10"
                                  />
                                );
                              }}
                            ></Controller>
                          </td>

                          <td width="280">
                            <Controller
                              control={control}
                              name={`popbillAcctList.${accountIdx}.accountHolder`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <div className="flex-center">
                                    <BaseInput
                                      value={value}
                                      name={name}
                                      disabled
                                      className="minmax160 text-right w-100"
                                    />
                                    <BaseButton
                                      title="예금주 조회"
                                      type="button"
                                      className="mx10"
                                      onClick={() => {
                                        const request = {
                                          accountNumber: account.accountNumber,
                                          bankCode: account.bankCode,
                                          idx: accountIdx,
                                        };
                                        confirmAccountHolder(request);
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            ></Controller>
                          </td>
                          <td width="420" className="px10">
                            <div className="flex-center">
                              <BaseButton title="선택" className="color-white" disabled />

                              <ul className="list-none flex-center flex-wrap px10">
                                {account.popbillAcctAllocList &&
                                account.popbillAcctAllocList.length > 0 &&
                                partnerList &&
                                partnerList?.length > 0 ? (
                                  partnerList?.map((partner, idx) => (
                                    <li key={partner.id} className="mr15">
                                      <span>{partner.id} </span>
                                      <span>{`(${partner.code})`}</span>
                                      {idx !== partnerList?.length - 1 && <span> ,</span>}
                                    </li>
                                  ))
                                ) : (
                                  <li>
                                    <span className="font16">-</span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </td>
                          <td width="40">
                            <div className="flex-center-center">
                              <button
                                className="base-erase-btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onDeleteAccount(accountIdx);
                                }}
                              ></button>
                            </div>
                          </td>
                        </tr>

                        {errors.popbillAcctList && (
                          <>
                            {errors.popbillAcctList[accountIdx]?.bankCode ? (
                              <p className="validation-text pt0 pb10">
                                {errors.popbillAcctList[accountIdx]?.bankCode?.message}
                              </p>
                            ) : errors.popbillAcctList[accountIdx]?.accountNumber ? (
                              <p className="validation-text pt0 pb10">
                                {errors.popbillAcctList[accountIdx]?.accountNumber?.message}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </Fragment>
                    );
                  },
                )}
              </tbody>
            </table>
          </section>
        </article>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton title="저장" type="submit" className="size-large" />
        </div>
      </div>

      {alertModal.isOpen && (
        <BaseModal
          title={alertModal.title}
          isOpen={alertModal.isOpen}
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
          btnRightTitle="확인"
        >
          {alertModal.message}
        </BaseModal>
      )}
    </div>
  );
};

export default PopbillAccountForm;
