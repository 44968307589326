import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPartners } from "src/api/partner";
import { Partner } from "src/api/partner/type";
import { addPopbillAccountAllocAsync } from "src/api/popbill/popbill-api";
import { PopbillAccountModel, PopbillBizDetailModel } from "src/api/popbill/popbill-types";
import { getBankCodeListAsync } from "src/api/provider/provider-api";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { PagePath } from "src/pages/product/details";

type Props = {
  refetchPopbill: () => void;
  popbill?: PopbillBizDetailModel;
};

const popbillAccountWidth = {
  fullWidth: "1400px",
  minmax80: "80",
  minmax120: "120",
  minmax160: "160",
  minmax200: "200",
  minmax400: "400",
};

const PopbillAccountDetail = ({ refetchPopbill, popbill }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const popbillAcctList = useMemo(() => popbill?.popbillAcctList, [popbill]);

  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 팝빌 은행코드 api
  const { executeAsync: getBankCodeList } = useApiOperation(getBankCodeListAsync);

  // 팝빌 사업자 파트너 할당
  const { executeAsync: addPopbillAccountAlloc } = useApiOperation(addPopbillAccountAllocAsync);

  // 파트너 목록 조회
  const { executeAsync: getPartnersAsync } = useApiOperation(getPartners);

  // 팝빌 은행코드 목록 조회 api
  const { data: bankCodeList } = useQuery({
    queryKey: ["bankCodeList"],
    queryFn: () => getBankCodeList({ codeGroup: "POPBILL_BANK_CODE" }),
    select: (response) => {
      const convertList = response.data.data.content.map((item) => ({
        value: item.code,
        label: item.desc,
      }));
      return convertList.sort((a, b) => (a.label < b.label ? -1 : 1));
    },
  });

  // 파트너 목록 보여주기 위한 api
  const { data: partnerList } = useQuery({
    queryKey: ["partnerList", popbillAcctList?.map((item) => item.popbillAcctAllocList)],
    queryFn: () => {
      const request = {
        page: 0,
        size: 20,
        id: popbillAcctList
          ?.flatMap(
            (item: PopbillAccountModel) =>
              item.popbillAcctAllocList?.map((alloc) => alloc.partnerId) || [],
          )
          .join(","),
      };

      return getPartnersAsync(request);
    },
    select: (response) => response.data.data.content || [],
    enabled: (() => {
      const hasAllocList = popbillAcctList?.some((item) => {
        return Array.isArray(item?.popbillAcctAllocList) && item.popbillAcctAllocList.length > 0;
      });

      return Boolean(hasAllocList);
    })(),
  });

  //팝빌 계좌 > 파트너 할당
  const { mutate: addPopbillAccount } = useMutation({
    mutationFn: async (selectedPartners: Partner[]) => {
      const popbillAcct = popbillAcctList?.find(
        (item) => item.pbAccountId === partnerModal.payload,
      )?.popbillAcctAllocList;
      // 삭제할 파트너
      const deletePartners = popbillAcct
        ?.filter((partner) => {
          const isSamePartner = selectedPartners.some(
            (acct) => String(acct.id) === String(partner?.partnerId),
          );
          return !isSamePartner;
        })
        .map((partner) => ({
          ...partner,
          isDeleted: true,
        }));

      // 새로 추가할 파트너
      const newAddedPartners = selectedPartners.filter((partner) => {
        const isSamePartner = popbillAcct?.some(
          (acct) => String(acct?.partnerId) === String(partner.id),
        );
        return !isSamePartner;
      });

      const popbillAcctAllocList = [
        ...(deletePartners?.map((partner) => ({
          ...partner,
          isDeleted: true,
        })) || []),
        ...(newAddedPartners.map((partner) => ({
          partnerId: Number(partner.id),
        })) || []),
      ];

      return addPopbillAccountAlloc({
        pbAccountId: String(partnerModal.payload),
        popbillAcctAllocList: popbillAcctAllocList || [],
      });
    },
    onSuccess: ({ status }) => {
      // 모달 닫기
      setPartnerModal({ isOpen: false });
      setConfirmModal({ isOpen: false });

      refetchPopbill();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <div className="contents-container__scroll">
      <div className="contents-container__wrap popbill-account">
        <div className="contents-container__wrap-contents">
          {/* <ArticleSection title="계좌 조회 대상" className="pt20 pb0 px0"> */}
          <article className={"pt20 pb0 px0 contents-container__wrap-article full-width"}>
            <div className="contents-container__sub-title">
              <h2 className="mr5">계좌 조회 대상</h2>
              <BaseTooltip
                touchIcon="QUESTION"
                className="minmax16"
                children={
                  <ul className="pl15">
                    <li className="mb10">
                      계좌 조회 대상의 각 계좌의 등록 상태는 Ctrl.room_정산정보에서 확인할 수
                      있습니다.
                    </li>
                    <li>
                      계좌 조회 대상 파트너에서 동일한 계좌가 등록된 정산정보 상세에서 조회
                      가능합니다.
                    </li>
                  </ul>
                }
              />
            </div>
            <section className="contents-container__1070 overflow-x-scroll">
              <table className="inner-table" width={popbillAccountWidth.fullWidth}>
                <thead>
                  <tr className="border-bottom border-top">
                    <th className={`minmax${popbillAccountWidth.minmax80}`}>
                      <span>은행</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax160}`}>
                      <span>계좌번호</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax120}`}>
                      <span>예금주</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax80}`}>
                      <span>연동 여부</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax160}`}>
                      <span>최근 연동 상태</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax160}`}>
                      <span>최근 연동 일시</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax120}`}>
                      <span>연동 실패 횟수</span>
                    </th>
                    <th className={`minmax${popbillAccountWidth.minmax400}`}>
                      <div className="flex-center-center">
                        <span>파트너 권한 부여</span>
                        <BaseTooltip
                          touchIcon="QUESTION"
                          className="ml5 minmax16"
                          children={
                            <ul className="pl15">
                              <li className="mb10">
                                파트너 권한 부여는 수정 화면에서 설정하거나 추가할 수 있습니다.
                              </li>
                              <li>
                                설정 시, 해당 파트너는 연동된 API의 서비스를 이용할 수 있습니다.
                              </li>
                            </ul>
                          }
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {popbillAcctList && popbillAcctList?.length > 0 ? (
                    popbillAcctList?.map((account: PopbillAccountModel, accountIdx: number) => {
                      return (
                        <Fragment key={accountIdx}>
                          <tr>
                            <td width={popbillAccountWidth.minmax80} className="text-center">
                              {bankCodeList?.find((item) => item.value === account.bankCode)?.label}
                            </td>

                            <td width={popbillAccountWidth.minmax160} className="text-center">
                              {account.accountNumber}
                            </td>

                            <td width={popbillAccountWidth.minmax120} className="text-center">
                              {account.accountHolder}
                            </td>
                            <td width={popbillAccountWidth.minmax80} className="text-center">
                              <span
                                className={
                                  account?.syncCode && account.syncCode < 0
                                    ? "chip red"
                                    : account?.syncCode && account.syncCode > 0
                                    ? "chip green"
                                    : ""
                                }
                              >
                                {account?.syncCode && account.syncCode < 0
                                  ? "연동 실패"
                                  : account?.syncCode && account.syncCode > 0
                                  ? "연동 성공"
                                  : "-"}
                              </span>
                            </td>
                            <td width={popbillAccountWidth.minmax160} className="text-center">
                              <BaseTooltip contents={account.syncMessage || "-"} />
                            </td>
                            <td width={popbillAccountWidth.minmax120} className="text-center">
                              {account.syncDate ? (
                                moment(account.syncDate).format("YYYY.MM.DD HH:mm:ss")
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td width={popbillAccountWidth.minmax120} className="text-center">
                              <span>{account.errorCount}</span>
                            </td>
                            <td width={popbillAccountWidth.minmax400} className="flex-center px10">
                              <>
                                <BaseButton
                                  title="선택"
                                  className="size-small color-white "
                                  disabled={!popbill?.isVerifiedBankAccount}
                                  onClick={() => {
                                    setPartnerModal({ isOpen: true, payload: account.pbAccountId });
                                  }}
                                />
                                {partnerModal.isOpen &&
                                  partnerModal.payload === account.pbAccountId && (
                                    <PartnerSelectModal
                                      onCanceled={() => setPartnerModal({ isOpen: false })}
                                      multiSelect
                                      onAdded={(selected) => {
                                        setConfirmModal({
                                          isOpen: true,
                                          title: "API 연동 권한을 부여하시겠습니까?",
                                          message:
                                            "권한을 부여하면 해당 파트너에서 API를 통해 시스템이 연동됩니다.",
                                          payload: selected,
                                        });
                                      }}
                                      defaultValues={
                                        partnerList?.filter((partner) =>
                                          account.popbillAcctAllocList?.some(
                                            (popbillAcct) =>
                                              Number(popbillAcct.partnerId) === Number(partner.id),
                                          ),
                                        ) || []
                                      }
                                    />
                                  )}

                                <ul className="list-none flex-center flex-wrap px10">
                                  {account.popbillAcctAllocList &&
                                  account.popbillAcctAllocList.length > 0 ? (
                                    account.popbillAcctAllocList?.map((popbillAcct, idx) => (
                                      <li key={popbillAcct.allocAccountId} className="mr15">
                                        <span>{popbillAcct.partnerId} </span>
                                        <span>{`(${
                                          partnerList?.find(
                                            (partner) =>
                                              Number(partner.id) === Number(popbillAcct.partnerId),
                                          )?.code
                                        })`}</span>
                                        {idx !==
                                          (account.popbillAcctAllocList?.length || 0) - 1 && (
                                          <span> ,</span>
                                        )}
                                      </li>
                                    ))
                                  ) : (
                                    <li>
                                      <span className="font16">-</span>
                                    </li>
                                  )}
                                </ul>
                              </>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        <span>데이터가 없습니다.</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
          </article>
          {/* </ArticleSection> */}
        </div>

        {/* 버튼 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>
          <div className="right-area">
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => navigate(`${PagePath.popbill.form}?id=${id}&tab=accountInfo`)}
            />
          </div>
        </div>
      </div>

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          title={confirmModal.title}
          onClick={() => addPopbillAccount(confirmModal.payload)}
          onClose={() => setConfirmModal({ isOpen: false })}
          btnRightTitle="확인"
          btnLeftTitle="취소"
        >
          <span className="font12 text-primary3">{confirmModal.message}</span>
        </BaseModal>
      )}
    </div>
  );
};

export default PopbillAccountDetail;
